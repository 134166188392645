import React, { useEffect } from "react";
import loadable from "@loadable/component";

import { Divider, Paper } from "@mui/material";
import Aos from "aos";

import "./Home.css";

import HomeSlider from "../../components/HomeSlider/HomeSlider";
import { Helmet } from "react-helmet";

const ISOffersYou = loadable(() =>
  import("../../components/ISOffersYou/ISOffersYou")
);
const ISCourses = loadable(() =>
  import("../../components/ISCoursesNew/ISCourses")
);
const SlickPlacements = loadable(() =>
  import("../../components/SlickPlacements/SlickPlacements")
);
const PlusPlacements = loadable(() =>
  import("../../components/PlusPlacements/PlusPlacements")
);
const PlusCompanies = loadable(() =>
  import("../../components/PlusCompanies/PlusCompanies")
);
const PlusTestimonials = loadable(() =>
  import("../../components/PlusTestimonials/PlusTestimonials")
);

const isMobile = window.innerWidth <= 960;

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="home">
      <Helmet>
        <title>Home | Internship Studio</title>
      </Helmet>
      <HomeSlider page="home" />

      <ISOffersYou />

      <div id="courses"></div>
      <ISCourses />

      <Paper
        className="sidebar-section"
        data-aos-delay="300"
        data-aos="fade-right"
        id="placements"
        sx={{
          marginBottom: "0px",
          borderRadius: "0px",
        }}
      >
        <p className="h2">Placements</p>
        <Divider className="divider" />
        {isMobile ? (
          <SlickPlacements slidesToShowLarge={4} />
        ) : (
          <PlusPlacements />
        )}
      </Paper>

      <Paper
        className="sidebar-section lightBlueBackground"
        data-aos-delay="300"
        data-aos="fade-left"
        sx={{
          marginBottom: "0px",
          borderRadius: "0px",
        }}
      >
        <p className="h2">Our students work at</p>
        <Divider className="divider" />
        <PlusCompanies />
      </Paper>

      <Paper
        className="sidebar-section"
        data-aos-delay="300"
        data-aos="fade-right"
        id="testimonials"
        sx={{
          marginBottom: "0px",
          borderRadius: "0px",
        }}
      >
        <p className="h2">Testimonials</p>
        <Divider className="divider" />
        <PlusTestimonials page="home" />
      </Paper>
    </div>
  );
};

export default Home;
