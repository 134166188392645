import React from "react";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import { LazyLoadImage } from "react-lazy-load-image-component";

import Logo from "../../img/main-logo.webp";

import "./Footer.css";
import { Button, Divider } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Note from "./Note";
import Contact from "./Contact";

const Footer = () => {
  const location = useLocation();

  const ourEmail = location.pathname.includes("common-internship-test")
    ? "cit@internshipstudio.com"
    : "contact@internshipstudio.com";

  const exclude = ["common-internship-test", "landing"];

  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div
        className="footer-inner footer-top"
        style={{
          paddingBottom: location.pathname.includes("landing")
            ? "2rem"
            : "auto",
        }}
      >
        <div className="identity">
          <div className="logo">
            <LazyLoadImage
              src={Logo}
              alt="Internship Studio"
              height="75px"
              width="250px"
            />
          </div>
          {!location.pathname.includes("common-internship-test") && (
            <p>
              Internship studio offers you to kick start your career without any
              prior knowledge by co-ordinating with leading industry experts.
            </p>
          )}
          <div className="footer-social">
            <a
              href="https://www.linkedin.com/company/internship-studio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://www.instagram.com/internship_studio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/internshipstudio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCIJYb5d9rWfdvXcuMorxrNA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon />
            </a>
          </div>
        </div>

        {!location.pathname.includes("common-internship-test") && (
          <div className="links">
            <h3>Quick Links</h3>
            <ul>
              {location.pathname.includes("landing") && (
                <li>
                  <Link to="/">Home</Link>
                </li>
              )}
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Use</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund">Refund Policy</Link>
              </li>
              <li>
                <Link to="/common-internship-test-june">
                  Common Internship Test
                </Link>
              </li>
              {location.pathname.includes("common-internship-test") && (
                <li>
                  <a
                    href="https://cit.internshipstudio.com/register/register_ca.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Become Campus Ambassador
                  </a>
                </li>
              )}
              {/* {location.pathname.includes("common-internship-test") && (
                <li>
                  <Link to="/advanced-certificate-in-digital-marketing-with-job-guarantee">
                    Advanced Digital Marketing Certification
                  </Link>
                </li>
              )} */}
            </ul>
          </div>
        )}

        <div className="contact">
          <div className="contact-inner address">
            <a
              href="https://goo.gl/maps/agVUkqVX97KHmJJi8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LocationOnIcon />
            </a>

            <a
              href="https://goo.gl/maps/agVUkqVX97KHmJJi8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Internship Studio, MVPM Spark, Lane No 6, Ram Indu Park, Nr.
              Balewadi High St, Baner, Pune-411045, Maharashtra, IN
            </a>
          </div>
          <div className="contact-inner email">
            <a
              href={`mailto: ${ourEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EmailIcon />
            </a>
            <a
              href={`mailto: ${ourEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ourEmail}
            </a>
          </div>
          <div className="contact-inner phone">
            <a
              href={`${location.pathname.includes("landing") ||
                location.pathname.includes("dm-sat")
                ? "tel:+918830657968"
                : "tel:+917507869003"
                }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LocalPhoneIcon />
            </a>
            <div>
              <a
                href={`${location.pathname.includes("landing") ||
                  location.pathname.includes("dm-sat")
                  ? "tel:+918830657968"
                  : "tel:+917507869003"
                  }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${location.pathname.includes("landing") ||
                  location.pathname.includes("dm-sat")
                  ? "+91 8830657968"
                  : "+91 7507869003"
                  }`}
              </a>
              <p>{"(Mon-Fri) (12PM-5PM)"}</p>
            </div>
          </div>
          <div className="support">
            <Button
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#000",
                },
              }}
              variant="contained"
              size="large"
              startIcon={<MailOutlineIcon />}
              component="a"
              href={`mailto: ${ourEmail}`}
            >
              Support
            </Button>
          </div>
        </div>
      </div>

      {location.pathname.includes("landing") && <Contact />}

      {/* {!exclude.some((el) => location.pathname.includes(el)) && (
        <>
          <Divider className="divider" />

          <div className="footer-inner footer-end">
            <div>
              <p>
                Registered office: 12A, Gopal, Kapileshwarnagar, Sarasnagar,
                Ahmednagar-414001
              </p>
              <p>CIN: U80902PN2019PTC186361</p>
            </div>
            <p>
              &copy; 2023 Internship Studio by Star Skillcare Education Private
              Limited
            </p>
          </div>
        </>
      )} */}

      <Divider className="divider" />

      <div className="footer-inner footer-end">
        <div>
          <p>
            Registered office: 12A, Gopal, Kapileshwarnagar, Sarasnagar,
            Ahmednagar-414001
          </p>
          <p>GSTIN: 27DHFPB3227M1ZC</p>
        </div>
        <p>
          &copy; {year} Internship Studio & Common Internship Test by Skill Universe
        </p>
      </div>

      {!location.pathname.includes("dm-sat") && <Note />}
    </div>
  );
};

export default Footer;
