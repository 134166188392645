import React from "react";

import "./App.css";

import { Routes, Route, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import loadable from "@loadable/component";

import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import HeaderCIT from "./components/Header/HeaderCIT";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import Home from "./pages/Home/Home";
import ThankYou2 from "./pages/ThankYou/ThankYou2";

const WebinarRegistration = loadable(
  () => import("./pages/WebinarRegistration/WebinarRegistration"),
  {
    fallback: <>Loading...</>,
  }
);
const Placements = loadable(() => import("./pages/Placements/Placements"), {
  fallback: <>Loading...</>,
});
const HireFromUs = loadable(() => import("./pages/HireFromUs/HireFromUs"), {
  fallback: <>Loading...</>,
});
const NotFound = loadable(() => import("./pages/NotFound/NotFound"), {
  fallback: <>Loading...</>,
});
const ThankYou = loadable(() => import("./pages/ThankYou/ThankYou"), {
  fallback: <>Loading...</>,
});
const Payment = loadable(() => import("./pages/Payment/Payment"), {
  fallback: <>Loading...</>,
});
const Privacy = loadable(() => import("./pages/Privacy/Privacy"), {
  fallback: <>Loading...</>,
});
const Refund = loadable(() => import("./pages/Refund/Refund"), {
  fallback: <>Loading...</>,
});
const About = loadable(() => import("./pages/About/About"), {
  fallback: <>Loading...</>,
});
const Terms = loadable(() => import("./pages/Terms/Terms"), {
  fallback: <>Loading...</>,
});
const Plus = loadable(() => import("./pages/Plus/Plus"), {
  fallback: <>Loading...</>,
});
const CIT = loadable(() => import("./pages/CIT/CIT"), {
  fallback: <>Loading...</>,
});
const CITAlt = loadable(() => import("./pages/CITAlt/CITAlt"), {
  fallback: <>Loading...</>,
});
const DMSAT = loadable(() => import("./pages/DMSAT/DMSAT"), {
  fallback: <>Loading...</>,
});

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

export default function App() {
  const location = useLocation();

  React.useEffect(() => {
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
      toast.error("Right click is disabled", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const routes = [
    {
      path: "/",
      element: <Home />,
    },
    // {
    //   path: "/landing/advanced-certificate-in-digital-marketing-with-job-guarantee",
    //   element: <Plus landing />,
    // },
    // {
    //   path: "/landing/advanced-certificate-in-digital-marketing-with-job-guarantee-in-india",
    //   element: <Plus location="india" landing />,
    // },
    // {
    //   path: "advanced-certificate-in-digital-marketing-with-job-guarantee",
    //   element: <Plus />,
    // },
    {
      path: "thank-you",
      element: <ThankYou />,
    },
    {
      path: "thank-you-dmsat",
      element: <ThankYou2 />,
    },
    {
      path: "placements",
      element: <Placements />,
    },
    {
      path: "hire-from-us",
      element: <HireFromUs />,
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "privacy",
      element: <Privacy />,
    },
    {
      path: "terms",
      element: <Terms />,
    },
    {
      path: "refund",
      element: <Refund />,
    },
    {
      path: "webinar-registration",
      element: <WebinarRegistration />,
    },
    {
      path: "payment",
      element: <Payment />,
    },
    {
      path: "common-internship-test-june",
      element: <CITAlt />,
    },
    {
      path: "common-internship-test",
      element: <CITAlt />,
    },
    {
      path: "dm-sat",
      element: <DMSAT />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const exclude = ["webinar-registration", "thank-you", "landing"];

  return (
    <ThemeProvider theme={theme}>
      <div className="main-container">
        <ScrollToTop />

        {exclude.some((el) => location.pathname.includes(el)) ? null : !(
          location.pathname.includes("common-internship-test") ||
          location.pathname.includes("dm-sat")
        ) ? (
          <>
            <Header />
            <hr className="header-hr" />
          </>
        ) : (
          <>
            <HeaderCIT />
            <hr className="header-hr" />
          </>
        )}

        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>

        {exclude.some((el) => location.pathname.includes(el)) ? (
          !location.pathname.includes("thank-you") && <Footer />
        ) : (
          <Footer />
        )}
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}
