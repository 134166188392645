import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import SchoolIcon from "@mui/icons-material/School";

import CTAModal from "../CTAModal/CTAModal";

import Logo from "../../img/main-logo-2.webp";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";

const pages = [
  {
    name: "Placements",
    link: "placements",
    // link: "#",
  },
  {
    name: "Hire from us",
    link: "https://hire.internshipstudio.com/login/",
  },
  {
    name: "About us",
    link: "about",
  },
];
const loginPages = [
  {
    name: "CIT",
    link: "https://cit.internshipstudio.com/login/",
  },
  {
    name: "Learning Portal",
    link: "https://learn.internshipstudio.com/learn/account/signin",
  },
];

const isMobile = window.innerWidth <= 768;

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSpecialization, setAnchorElSpecialization] =
    React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenSpecializationMenu = (event) => {
    setAnchorElSpecialization(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseSpecializationMenu = () => {
    setAnchorElSpecialization(null);
  };

  const { pathname } = useLocation();

  const exclude = [
    "advanced-certificate-in-digital-marketing-with-job-guarantee",
    "landing",
  ];

  return (
    <AppBar position="static" style={{ background: "#fff" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ mr: 4, display: { xs: "none", md: "flex" } }}>
            <img
              src={Logo}
              onClick={() => (window.location.href = "/")}
              style={{ cursor: "pointer" }}
              alt="Internship Studio"
              width="133px"
              height="50px"
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* <MenuItem onClick={handleOpenSpecializationMenu}>
                <Typography textAlign="center">
                  Specializations <ExpandMoreIcon />
                </Typography>
              </MenuItem> */}
              {pages.map((page, i) => (
                <MenuItem key={i} component={Link} to={page.link}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={handleOpenUserMenu}>
                <Typography textAlign="center">
                  Login <ExpandMoreIcon />
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ mr: 2, display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
            <img
              src={Logo}
              onClick={() => (window.location.href = "/")}
              alt="Internship Studio"
              height="40px"
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            {/* <Button
              onClick={handleOpenSpecializationMenu}
              sx={{
                my: 2,
                display: "flex",
                color: "#000",
                fontWeight: "500",
                textTransform: "uppercase",
                fontSize: "14px",
              }}
            >
              Specializations <ExpandMoreIcon />
            </Button> */}
            {pages.map((page, i) => (
              <Link
                key={i}
                style={{
                  display: "block",
                  margin: "0 10px",
                  color: "#000",
                  fontWeight: "500",
                  textTransform: "uppercase",
                  fontSize: "14px",
                }}
                to={page.link}
              >
                {page.name}
              </Link>
            ))}
            <Button
              onClick={handleOpenUserMenu}
              sx={{
                my: 2,
                display: "flex",
                color: "#000",
                fontWeight: "500",
                textTransform: "uppercase",
                fontSize: "14px",
              }}
            >
              Login <ExpandMoreIcon />
            </Button>
          </Box>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {loginPages.map((login, i) => (
              <MenuItem
                key={i}
                onClick={() => window.open(`${login.link}`, "_blank")}
              >
                <Typography textAlign="center">{login.name}</Typography>
              </MenuItem>
            ))}
          </Menu>

          {/* <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElSpecialization}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(anchorElSpecialization)}
            onClose={handleCloseSpecializationMenu}
          >
            <MenuItem>
              <Typography
                onClick={() => {
                  handleCloseSpecializationMenu();
                  handleCloseNavMenu();
                }}
                component={Link}
                to="/advanced-certificate-in-digital-marketing-with-job-guarantee"
                textAlign="center"
              >
                Advanced Digital Marketing Certification with 100% Job Guarantee
              </Typography>
            </MenuItem>
          </Menu> */}

          {!isMobile &&
            (exclude.some((item) => pathname.includes(item)) ? (
              <Box sx={{ flexGrow: 0 }}>
                <CTAModal
                  text="BOOK A FREE DEMO"
                  logo={true}
                  page={pathname}
                  type="header"
                />
              </Box>
            ) : (
              <>
                <Button
                  variant="contained"
                  sx={{
                    background: "#fff",
                    color: "#000",
                    "&:hover": {
                      background: "#fff",
                    },
                  }}
                  component={Link}
                  to="/common-internship-test-june"
                  startIcon={<SchoolIcon />}
                >
                  Common Internship Test
                </Button>
              </>
            ))}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
